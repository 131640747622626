import { useState } from "react";

import { SortConsultatuon } from "../EditConsultation/SortConsultatuon";

export const StatisticsFullInfoConsultation = () => {
  const [data, setData] = useState([]);
  const [showSortContainer, setShowSortContainer] = useState(false);

  const toggleContainer = () => {
    setShowSortContainer(!showSortContainer);
    document.body.style.overflow = showSortContainer ? "auto" : "hidden";
  };

  function formatDate(dateStr) {
    const parts = dateStr.split("-"); // Разбиваем строку на компоненты
    if (parts.length === 3) {
      const year = parts[0];
      const month = parts[1];
      const day = parts[2];
      return `${day}-${month}-${year}`; // Собираем дату в нужном формате
    }
    return dateStr; // Если формат не соответствует ожидаемому
  }

  return (
    <div className="cons-big-full-container">
      <div className="container-sort-full-info">
        <button className="btn-sort-full-info" onClick={toggleContainer}>
          <i className="bx bx-sort bx-sm" onClick={toggleContainer}></i>
        </button>
      </div>
      <div
        className={`container-sort-cons ${showSortContainer ? "visible" : ""}`}
      >
        <i className="bx bx-x bx-lg cross" onClick={toggleContainer}></i>

        <SortConsultatuon
          data={setData}
          showUserSearchButton={true}
          setShowSortContainer={setShowSortContainer}
        />
      </div>

      <div className="cons-big-container-data">
        {Object.keys(data).map((date) => (
          <div key={date}>
            <h2 className="cons-big-container-title-date">
              {formatDate(date)}
            </h2>
            <div className="cons-big-table-container">
              <table className="cons-big-table-title">
                <thead>
                  <tr>
                    <th className="cons-big-container-data-size1">
                      Номер талону кв.
                    </th>
                    <th className="cons-full-data-size">Відвідувач / Хворий</th>
                    <th className="cons-full-data-size1">Лікар</th>
                    <th className="cons-full-data-size3">
                      <p>Оплата</p>
                      <p>Опл.</p>
                    </th>
                    <th className="cons-full-data-size3">Вип. ліки</th>
                    <th className="cons-full-data-size2">Примітки</th>
                  </tr>
                </thead>

                <tbody>
                  {data[date].map((consultation) => (
                    <tr
                      key={consultation.id}
                      className="cons-big-container-data-full"
                    >
                      <td className="cons-big-container-data-size1">
                        <p className="data-receipt_number">
                          {consultation.receipt_number}
                        </p>
                      </td>
                      <td className="cons-full-data-size">
                        <div className="cons-big-container-data-patient">
                          <div>
                            <p>{consultation.patient.full_name}</p>
                          </div>
                          <div>
                            <p>{consultation.patient.phone}</p>
                          </div>
                        </div>
                      </td>
                      <td className="cons-full-data-size1">
                        <p>{consultation.user.full_name}</p>
                      </td>
                      <td className="cons-full-data-size5">
                        <p>{consultation.payment_amount}</p>
                      </td>
                      <td className="cons-full-data-size4">
                        {consultation.medication_prescribed ? (
                          <i className="bx bx-check bx-md"></i>
                        ) : null}
                      </td>
                      <td className="cons-full-data-size4-mob">
                        {consultation.medication_prescribed ? (
                          <i className="bx bx-check bx-sm"></i>
                        ) : null}
                      </td>
                      <td className="cons-full-data-size2">
                        <p className="cons-full-data-size-not">
                          {consultation.notation}
                        </p>
                      </td>
                    </tr>
                  ))}
                  <tr className="cons-big-container-data-total">
                    <td className="cons-big-container-data-text1">Всього:</td>
                    <td className="cons-big-container-data-text2">
                      {data[date].length}
                    </td>
                    <td className="cons-big-container-data-text2">
                      {
                        [
                          ...new Set(
                            data[date].map(
                              (consultation) => consultation.user.id
                            )
                          ),
                        ].length
                      }
                    </td>
                    <td className="cons-big-container-data-text2">
                      {data[date].reduce(
                        (total, consultation) =>
                          total + parseFloat(consultation.payment_amount),
                        0
                      )}
                    </td>
                    <td className="cons-big-container-data-text2">
                      {
                        data[date].filter(
                          (consultation) => consultation.medication_prescribed
                        ).length
                      }
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
