import { createContext, useState } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({});

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

// Для чего это нужно?
// Этот код используется для управления аутентификацией в React-приложении. 
// Он позволяет легко передавать данные об аутентификации через компонентное
//  дерево и обновлять их при необходимости.

// Например, вы можете иметь несколько разных страниц или компонентов,
// которым нужно знать, является ли пользователь в данный момент 
// аутентифицированным. Вместо того чтобы передавать эту информацию через каждый 
// компонент, вы можете использовать этот контекст, чтобы легко получить доступ к 
// ней в любом компоненте, который ее нуждается.

// Это может быть полезно в следующих сценариях:

// Отображение разных компонентов в зависимости от того, является ли пользователь 
// аутентифицированным. Управление доступом к определенным маршрутам или функциям в 
// зависимости от статуса аутентификации пользователя. Легкое обновление состояния 
// аутентификации во всем приложении, например, после входа в систему или выхода из нее.
